import React from "react"

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import PostTitle from "gatsby-theme-blog/src/components/post-title"
import PostDate from "gatsby-theme-blog/src/components/post-date"
import PostFooter from "gatsby-theme-blog/src/components/post-footer"
import { DiscussionEmbed } from "disqus-react"

const Post = ({
  data: {
    post,
    slug,
    site: {
      siteMetadata: { title },
    },
  },
  location,
  previous,
  next,
}) => (
    <Layout location={location} title={title}>
      <SEO title={post.title} description={post.excerpt} />
      <main>
        <PostTitle>{post.title}</PostTitle>
        <PostDate>{post.date}</PostDate>
        <MDXRenderer>{post.body}</MDXRenderer>
        <DiscussionEmbed
          shortname={process.env.GATSBY_DISQUS_NAME}
          config={{ identifier: slug, title }}
        />
      </main>
      <PostFooter {...{ previous, next }} />
    </Layout>
  )

export default Post
